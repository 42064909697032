require('./bootstrap');

window.Vue = require('vue').default;
import router from './router'
import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import App from './App.vue'
import axios from 'axios'; // 导入 axios
import '@mdi/font/css/materialdesignicons.css';

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    encrypted: true, // Ensure this is set if using TLS
});


Vue.use(Vuetify)
Vue.use(axios)


// 添加请求拦截器
// axios.interceptors.request.use(function (config) {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// });

const app = new Vue({
    el: '#app',
    router,
    vuetify: new Vuetify(), // 添加这一行
    components: { App },
    template: '<App />'
});