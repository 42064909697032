<template>
    <v-container>
      <!-- 使用 Vuetify 的 v-btn 组件替换原生 button -->
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-btn
            color="primary"
            :disabled="loading"
            @click="sendUnlockRequest"
            block
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="white"
              size="20"
            ></v-progress-circular>
            Unlock Vehicle
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show">
        {{ snackbar.message }}
        <v-btn color="red" text @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </template>

<script>
export default {
    data() {
    return {
      loading: false,
      snackbar: {
        show: false,
        message: ''
        }
        };
    },
    mounted() {
    window.Echo.channel('vehicle-channel')
        .listen('.vehicle-unlocked', (e) => {
            alert(`Message: ${e.message}`);
            });
    },
    methods: {
        sendUnlockRequest() {
            this.loading = true;
            axios.post('/api/unlock', { vehicleId: '12345' })
                .then(response => {
                this.snackbar.message = 'Unlock request sent!';
                this.snackbar.show = true;
                })
                .catch(error => {
                this.snackbar.message = 'Failed to send unlock request';
                this.snackbar.show = true;
                })
                .finally(() => {
                this.loading = false;
                });
            }
    }
}
</script>