<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-card class="pa-4">
              <v-card-title class="text-h5">Login</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-text-field
                    label="Email"
                    type="email"
                    v-model="email"
                    outlined
                    dense
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Password"
                    type="password"
                    v-model="password"
                    outlined
                    dense
                    required
                    append-icon="mdi-eye"
                    @click:append="togglePasswordVisibility"
                  ></v-text-field>
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    large
                  >
                    Login
                  </v-btn>
                  <p v-if="error" class="red--text">Email or password is incorrect</p>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: false
    } 
  },
  methods: {
    login() {
      axios.post('/api/auth/login', {
        email: this.email,
        password: this.password,
        device_name: 'browser'
      })
      .then(response => {
        // Save the token
        localStorage.setItem('token', response.data.token);
        this.$router.push('/car');
        
        Swal.fire({
          title: 'Success!',
          text: 'Login successfully.',
          icon: 'success',
          showConfirmButton: false, // 不显示确认按钮
          timer: 2000 // 1秒后自动关闭
        });
      })
      .catch(error => {
        this.error = true;
        console.log(error);
      });
    }
  }
}
</script>