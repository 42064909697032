<template>
  <v-app>
    <v-toolbar color="indigo" dark class="custom-toolbar">
      <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <v-toolbar-title>Car</v-toolbar-title>
      <v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn text to="/" v-if="!isLoggedIn" class="hidden-sm-and-down">Home Page</v-btn>
        <v-btn text to="/car" v-if="isLoggedIn" class="hidden-sm-and-down">Car</v-btn>
        <v-btn text @click.prevent="logout" v-if="isLoggedIn" class="hidden-sm-and-down">Logout</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app class="hidden-sm-and-up">
      <v-list>
        <v-list-item link to="/" v-if="!isLoggedIn">
          <v-list-item-title>Home Page</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/car" v-if="isLoggedIn">
          <v-list-item-title>Car</v-list-item-title>
        </v-list-item>
        <v-list-item link @click.prevent="logout" v-if="isLoggedIn">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      isLoggedIn: false
    };
  },
  // created() {
  //   if (this.isLoggedIn) {
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  //   }
  // },
  // created() {
  //   this.checkLoginStatus();
  // },
  computed: {
    showMenu() {
      return this.isLoggedIn;
    }
  },
  watch: {
    '$route': function() {
      this.isLoggedIn = !!localStorage.getItem('token');
    }
  },
  mounted() {
    this.checkLoginStatus(); // 初始检查
    this.interval = setInterval(this.checkLoginStatus, 600000); // 每10分钟检查一次
    this.reloadInterval = setInterval(() => {
      window.location.reload();
    }, 3600000); // 3600000 毫秒 = 1 小时
  },
  methods: {
    beforeDestroy() {
      clearInterval(this.interval);
    },
    checkLoginStatus() {
      console.log("Checking login status, current token:", localStorage.getItem('token'));
          const token = localStorage.getItem('token');
      if (token) {
        axios.get('/api/auth/validate', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.isLoggedIn = true;
        }).catch(error => {
          console.error("Error validating token:", error);
          console.error("Detailed error:", error.response ? error.response.data : "No response data");
          this.isLoggedIn = false;
          localStorage.removeItem('token');
          this.promptLogin();
        });
      } else {
        console.error("Error validating token:", error);
        this.isLoggedIn = false;
        localStorage.removeItem('token');
        this.promptLogin();
        this.$forceUpdate(); 
      }
    },
    promptLogin() {
      Swal.fire({
        title: 'Session Expired',
        text: 'Please log in again to continue.',
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          this.$router.push('/');
        }
      });
    },
    logout() {
      // Send a request to the logout API
      axios.post('/api/auth/logout', {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        // Remove the token from localStorage
        localStorage.removeItem('token');
        this.isLoggedIn = false;
        // Redirect to the login page
        Swal.fire({
          title: 'Success!',
          text: 'Logout successfully.',
          icon: 'success',
          showConfirmButton: false, // 不显示确认按钮
          timer: 2000 // 1秒后自动关闭
        });
      this.$router.push('/');
      })
      .catch(error => {
        console.log(error);
        this.isLoggedIn = false;
        localStorage.removeItem('token');
      });
    }
  }
}
</script>
<style>
/* 确保 v-app 填充整个屏幕 */
.v-application {
  min-height: 100vh; /* 视口高度 */
  overflow-x: hidden; /* 隐藏水平滚动条 */
}

/* 调整 v-main 的样式 */
.v-main {
  max-width: 728px;
  overflow-x: hidden; /* 隐藏水平滚动条 */
  padding: 0 !important; /* 移除内边距 */
}

/* 响应式调整 */
@media (max-width: 600px) {
  .custom-toolbar {
    max-height: 50px; /* 在小屏幕上减小工具栏高度 */
  }
  .v-main {
    max-width: 728px;
    padding: 8px; /* 在小屏幕上减小内边距 */
  }
}
</style>