import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from './components/LoginPage.vue'
import Car from './components/Car.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/admin', component: AdminPage, meta: { requiresAuth: true }},
  { path: '/car', component: Car, meta: { requiresAuth: true }},
  { path: '/', component: LoginPage },
  // {
  //   path: '/tours/:id',
  //   name: 'TourDetail',
  //   component: TourDetail,
  // }
  // 更多的路由...
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 这个路由需要用户登录
    if (!localStorage.getItem('token')) {
      // 用户没有登录，重定向到登录页面
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next() // 用户已经登录，允许访问
    }
  } else {
    next() // 这个路由不需要用户登录，总是允许访问
  }
})

export default router